<template>
  <div>
    <div class="hero">
      <h1>special thanks</h1>
      <p>This is a list of people I want to thank because they impacted my life, have a special role in it or supported me.</p>
      <span>
        Please be aware that this list isn't complete and will never be.
        If you're not on the list please don't feel bad.
        Most of the people on here are the ones not expecting it.
        The people that I've known for years would need an own page just for them.
        Also please note that most of the people on here are German speaking, but since my entire website is in English I'll keep this page english as well.
      </span>
      <p>
        <router-link id="back" title="◄ go back" to="/">◄ back</router-link>
      </p>
    </div>
    
    <div class="project" v-for="c in credits" :key="c.name">
      <h2 :id="c.name.toLowerCase().replace(' ', '-')">{{c.name}}<a :href="`#${c.name.toLowerCase().replace(' ', '-')}`" id="hyperlink"><b-icon icon="link45deg" class="icon"/></a></h2>
      <span>{{c.text}}</span>
    </div>

    <div style="margin-bottom: 10rem;">
      <span>
        want your name removed from this list?
        <br>
        just let me know via
        <a id="hyperlink" href="mailto:hello@marcrufeis.de">email</a>
      </span>
    </div>

  </div>
</template>

<script>
  import credits from '@/assets/texts/credits';
  export default {
    name: 'Projects',
    data() {
      return {
        modalImg: '',
        credits,
      };
    },
    methods: {
      showImg(img) {
        this.modalImg = img;
        // this.$bvModal.show('imgModal')
      },
    }
  }
</script>

<style scoped>

p {
  color: #3b3b3b;
  font-size: 300%;
  margin: 0;
}

  .hero {
    height: 100vh;
  }

  .project {
    margin-bottom: 10rem;
  }

  .icon {
    margin-left: 1rem;
    font-size: 16pt;
  }
</style>